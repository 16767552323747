@import (inline) "reset.css";
@import (inline) "animate.css";

html, body {
    height: 100vh;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.container {
    padding: 0 15px;

}
.breakout_bg {
    &.grey {
        background-color: #F6F6F5;
    }

    &.pink {
        background-color: #EE52AA;
    }

    &.blue {
        background: radial-gradient(#18B4F7, #1B7CC5);
    }
}
.hidden { display: none; }
strong { font-weight: 700; }
small { font-size: .8em; }
img { max-width: 100%; height: auto; }
p {
    margin-bottom: 10px;
    line-height: 1.5em;
}

a.btn {
    display: inline-block;
    min-width: 250px;
    text-align: center;

    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1em;
    padding: 12px;
    border-radius: 8px;
    transition: .2s all ease-in;
    text-shadow: 1px 1px rgba(0, 0, 0, .1);
    background-color: #BE2988;
    &:hover {
        background-color: darken(#BE2988, 5%);
    }
}

h1 {
    font-weight: 700;
    font-size: 2.4em;
    margin-bottom: 25px;
    text-transform: uppercase;
    line-height: 1.4em;
}

h2 {
    color: #414141;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4em;
    font-family: "Chivo", sans-serif;
    margin-bottom: 15px;
}

h3 {
    color: #BF2887;
    text-align: center;
    text-transform: uppercase;
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: 1.9em;
}

h4 {
    color: #F052A9;
    text-align: center;
    text-transform: uppercase;
    font-family: "Chivo", sans-serif;
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 25px;
}

@media(min-width: 600px){
    .mobile_only { display: none; }
    .desktop_only { display: block; }
}
@media(max-width: 601px){
    .mobile_only { display: block; }
    .desktop_only { display: none; }
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.container {
    max-width: 1024px;
    margin: 0 auto;
}

#content .container {
    padding-top: 40px;
    padding-bottom: 40px;
}


#main_header {
    background: linear-gradient(to bottom, #00B3E3, #00B3E3);
    position: relative;
    overflow: hidden;

    & > .container.top {
        z-index: 100;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 10px;

        .logo {
            flex-basis: 125px;
        }

        .main_nav {
            flex-basis: calc(~"100% - 135px");
            display: flex;
            justify-content: flex-end;

            & > li {
                margin-left: 15px;

                a {
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: .8em;
                    padding: 6px 20px;
                    border-radius: 8px;
                    transition: .2s all ease-in;
                    text-shadow: 1px 1px rgba(0, 0, 0, .1);
                    &:hover {
                        background-color: rgba(0, 0, 0, .2);
                    }
                }

                &.focus {
                    a {
                        font-size: .9rem;
                        background-color: #BE2988;
                        &:hover {
                            background-color: darken(#BE2988, 5%);
                        }
                    }
                }
            }
        }

        #mobile_menu_actions {
            display: none;
            position: absolute;
            right: 15px;
            top: 30px;

            a {
                display: block;
                width: 25px;
                height: 25px;
                text-indent: -9999em;

                &.expand {
                    background: transparent url("icon-hamburger.svg") 50% 50% no-repeat;
                }

                &.close {
                    background: transparent url("icon-x.svg") 50% 50% no-repeat;
                    background-size: 70%;
                }
            }
        }

        @media(max-width: 835px){
            flex-wrap: wrap;
            padding-left: 0;
            padding-right: 0;
            .logo {
                flex-basis: 100%; text-align: center; margin-bottom: 10px;
                img { max-width: 100px; }
            }

            .main_nav {
                display: none;
                flex-basis: 100%;

                &.expanded {
                    display: block;

                    li {
                        margin: 0;
                        background-color: #363636;
                        overflow: hidden;

                        &.focus {
                            background-color: #BE2988;
                            text-align: center;
                            a {
                                font-size: 1.2em;
                            }
                        }

                        a {
                            background: none;
                            padding: 20px 15px;
                            display: block;

                            &:hover { background: none; }
                        }
                    }
                }
            }

            #mobile_menu_actions { display: block; }
        }
    }
}

.sparkle {
    position: absolute;
    top: 0;
    width: 300px;
    z-index: 1;

    &.right {
        right: 0;
    }

    &.left {
        left: 0;
        transform: rotate(-180deg);
    }
}

.sparkle_bg {
    position: relative;
    overflow: hidden;

    .container {
        z-index: 100;
        position: relative;
    }
}


#homepage {
    .home_showcase {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;

        .copy {
            flex-basis: 40%;
        }

        .phone {
            line-height: 0;
            flex-basis: 40%;
        }

        @media(max-width: 660px){
            .copy, .phone {
                flex-basis: 100%;
                text-align: center;
            }

            .phone {
                margin-top: 20px;
                img { max-width: 300px; }
            }
        }

        h1 {
            color: #fff;
            font-size: 2.5em;
            font-weight: 700;
            font-family: "Chivo", sans-serif;
            text-shadow: 1px 1px rgba(0, 0, 0, .2);
            text-transform: unset;
            line-height: 1em;

            small {
                display: block;
                font-size: .7em;
                line-height: 1.4em;
                margin-top: 10px;
            }
        }

        .badges {
            margin-top: 20px;
        }
    }

    #how_it_works { text-align: center; }

    .games {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 40px;

        & > .game {
            flex-basis: 43%;
            margin-bottom: 40px;
            text-align: center;

            .img {
                height: 200px;
                margin-bottom: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            p { text-align: left; }

            @media(max-width: 660px){
                flex-basis: 100%;
            }
        }
    }
}

#real_rewards, #the_lucky_crown {
    p {
        font-family: "Chivo", sans-serif;
        margin: 0 auto 25px;

        strong { color: #EF52AA; }
    }
}

#rewards_container .sparkle { bottom: 0; top: unset; }

#real_rewards {
    text-align: center;

    h3 { margin-bottom: 30px; }

    h4 {
        font-size: 1.6em;
        color: #000;
        text-transform: none;
        font-weight: 700;

        span { color: #EF52AA; text-transform: uppercase; }
    }

    p {
        font-size: 1.2em;
        max-width: 600px;

        &.strong { font-weight: 700; }
    }

    .medals {
        display: flex;
        justify-content: space-between;
        max-width: 700px;
        margin: 0 auto 35px;

        .medal {
            flex-basis: 15%;
            text-align: center;

            &.bronze { color: #E6A361; }
            &.silver { color: #AEAFB0; }
            &.gold { color: #ECBA0F; }
            &.platinum { color: #2FCFFF; }

            img { max-height: 125px; }

            .medal-name {
                font-weight: 700;
                text-transform: uppercase;
                font-family: "Chivo", sans-serif;
                font-size: 1.3em;
                margin-top: 15px;
            }
        }

        @media(max-width: 660px){
            flex-wrap: wrap;
            .medal {
                flex-basis: 45%;
                justify-content: space-around;
                margin-bottom: 15px;

                img { max-height: 100px; }
            }
        }
    }

    .giftcards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 500px;
        margin: 0 auto;

        .card {
            flex-basis: 45%;
            text-align: center;
            margin-bottom: 20px;

            @media(max-width: 500px){
                flex-basis: 100%;
            }

            img {
                box-shadow: 2px 5px 8px rgba(0, 0, 0, .4);
                border-radius: 10px;
                transition: .1s all ease-in;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.simple_cta {
    text-align: center;
    h5 {
        color: #fff;
        font-size: 2.1em;
        font-weight: 700;
        font-family: "Chivo", sans-serif;
        text-shadow: 1px 1px rgba(0, 0, 0, .2);
        text-transform: uppercase;
        margin-bottom: 15px;

        small {
            display: block;
            font-size: .7em;
            line-height: 1.4em;
            margin-top: 5px;
        }
    }
}

#the_lucky_crown {
    text-align: center;

    h3 {
        color: #fff;
        font-size: 2rem;
        text-shadow: 1px 1px rgba(0, 0, 0, .2);
        margin: 0 auto 25px;

        span { color: #FED94A; }

        &.smaller {
            margin-top: 20px;
            max-width: 500px;
            font-size: 1.5rem;
            span { font-size: 2rem; display: block; margin-bottom: 5px; }
        }
    }

    .avatars { margin: 25px 0 45px; }

    p {
        font-size: 1.5em;
        max-width: 500px;
        color: #fff;
        text-shadow: 1px 1px rgba(0, 0, 0, .2);
    }
}

#winners {
    hr {
        margin: 20px -40px 20px -40px;
        border: 1px solid #00b3e3;
    }

    .winner {
        .info {
            padding-bottom: 20px;
            display: flex;
            .column {
                align-items: center;
                display: flex;
                font-weight: bold;
                color: #be2988;

                img {
                    margin-right: 10px;
                    max-height: 40px;
                }

                &.period {
                    width: 170px;
                }

                &.card {
                    width: 320px;
                }

                &.name {
                    width: 140px;
                }
            }
        }

        .questions-and-answers {
            padding-bottom: 10px;
            .question {
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: bold;
                color: #00b3e3;
            }
            .answer {
                font-size: 0.8em;
            }
        }
    }
}

@media (max-width: @mobileBreakpoint) {
    #winners {
        hr {
            margin-left: 0;
            margin-right: 0;
        }
        .winner {
            .info {
                display: inline-block;

                .column {
                    height: 40px;
                    line-height: 40px;
                    display: block;

                    &.card {
                        span {
                            padding-right: 10px;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}

#html_giftcard {
    background: none;

    body#giftcard {
        background: #fff url("confetti.png") 0 0 repeat-x;
        background-size: unset;
        padding: 20px 0;
        color: #898989;

        @media (max-width: 700px) {
            background-size: 200%;
        }
    }

    header { display: none; }
    footer { position: static; background-color: #333; height: auto; }

    h1, h2 {
        display: inline-block;
        margin: 0 auto 10px;
        padding: 5px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .8);
        margin: 0 auto 10px;

        font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        text-shadow: 1px 1px rgba(0, 0, 0, .1);
        font-weight: 800;
        text-align: center;

        @media (min-width: 700px) {
            display: block;
        }
    }

    h1 {
        font-size: 1.7em;
        color: #f150a8;
        border: 0;
    }

    h2 {
        font-size: 1.3em;
        color: #31cfff;
    }

    .tier_bronze { color: #e8a363; }
    .tier_silver { color: #969696; }
    .tier_gold { color: #efba0f; }
    .tier_platinum { color: #31cfff; }
    .medal { margin-bottom: 15px; }

    .description {
        text-align: center;
        font-size: 1rem;
        line-height: 1.4em;

        &.noapp {
            margin-top: 40px;
        }
    }

    form.enter {
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        color: #000;

        button.submit {
            display: block;
            width: 100%;
            max-width: 80%;
            margin: 0 auto;
            padding: 10px 5px;
            background: #be2887;
            color: #fff;
            font-size: 1em;
            font-weight: 700;
            text-shadow: 1px 1px rgba(0, 0, 0, .4);
            text-transform: uppercase;
            cursor: pointer;
            letter-spacing: .016em;
            border-radius: 5px;
            transition: .5s all ease;

            &.disabled {
                background: linear-gradient(to bottom, #a7a7a7, #6b6b6b);
                color: #c1c1c1
            }
        }
    }

    .fields {
        max-width: 600px;
        margin: 0 auto;

        .field {
            margin-bottom: 10px;
            text-align: left;

            label {
                display: block;
                font-size: .8em;
                color: #969696;
                margin-bottom: 5px;
            }

            input[type='text'], input[type='email'] {
                display: block;
                border: 1px solid #969696;
                font-size: .8em;
                padding: 8px 10px;
                border-radius: 5px;
                color: #898989;
                width: calc(~"100% - 22px");
            }
        }
    }

    .giftcards_grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 25px;
        max-width: 500px;

        .card {
            flex-basis: 47%;
            position: relative;
            margin-bottom: 10px;

            button {
                color: #757575;
                background: none;
                border: 0;
                font-size: 1rem;

                &:focus {
                    outline: 0;
                }

                img { max-width: calc(~"100% - 8px"); border: 4px solid transparent; border-radius: 10px; }
            }

            &.selected {

                button {
                    img { border-color: #00d6f3; }
                }
            }

        }
    }

    #rules {
        max-width: 500px;
        margin: 30px auto;
        color: #898989;
        font-size: .7em;
        text-align: center;
    }
}

form#reset_pass {
    display: block;
    margin: 0 auto 15px;
    padding: 10px;

    text-align: center;

    input {
        font-size: 1em;
        text-align: center;
        width: calc(~"100% - 8px");
        padding: 3px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    button {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: .8em;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);
        cursor: pointer;

        display: block;
        width: calc(~"100% - 10px");
        margin: 0 auto;
        background-color: #761251;
        max-width: 400px;
        border-radius: 5px;
        border: 0;
        padding: 10px 5px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
    }
}

@import (inline) "remodal.css";
@import (inline) "remodal-default-theme.css";

//Window Sizes
@mobileBreakpoint: 580px;

//Remodal Overides
div.remodal {
    border-radius: 5px;


    h2 {
        color: #6f6f6f;
        font-family: "Open Sans Condensed", sans-serif;
        font-size: 1.8em;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.4em;
    }

    .input {
        margin-bottom: 8px;

        input[type="text"], input[type="email"], input[type="password"] {
            background-color: #fff;
            width: calc(~"100% - 32px");
            font-size: .8em;
            border-radius: 3px;
            border: 0;
            padding: 10px 15px;
        }

        &.fake {
            background-color: #fff;
            color: #716f6f;
            text-align: left;
            width: calc(~"100% - 32px");
            border-radius: 3px;
            font-size: .8em;
            border: 1px solid #cdd0d0;
            padding: 6px 15px;

            label {
                padding-right: 5px;
            }

            .block {
                display: inline-block;
            }

            input[type='text']{
                padding: 0 10px;
                width: calc(~"100% - 20px");
            }

        }

        @media (max-width: @mobileBreakpoint) {
            &.fake.zipdob {
                width: 100%;
                border: 0;
                padding: 0 !important;

                input[type='text']{
                    padding: 10px;
                    width: calc(~"100% - 20px");
                    margin-bottom: 8px;
                }
            }
        }
    }

    button#login {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 1em;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);

        display: block;
        width: 80%;
        cursor: pointer;
        background-color: #761251;
        transition: background-color .5s;
        margin: 15px auto 5px;;
        border: 0;
        border-radius: 5px;
        padding: 10px 30px;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
        }
    }
}

.message {
    font-size: .9em;
    text-align: center;
    padding: 10px;
    background-color: #d2e7f3;
    border: 1px solid darken(#82c3d7, 10%);
    border-radius: 3px;
    max-width: 1024px;
    margin: 10px auto;

    &.success {
        background-color: #d2f3d6;
        border: 1px solid #67cb5b;
    }

    &.error {
        color: #fff;
        background-color: #b71111;
        border: 1px solid #cb5b5b;
    }
}

footer {
    background-color: #353637;
    padding: 15px 0;
    text-align: center;
    font-size: .8em;
    color: #909192;

    a { color: #15ABC6; text-decoration: none; }
}
